<template>
  <div>
    <b-table
      ref="personal-away-application-dt"
      id="personal-away-application-dt"
      table-class="table-head-custom table-vertical-center mt-5"
      :fields="fields"
      :items="items"
      :busy="isBusy"
      hover
      responsive
      show-empty
    >
      <!-- A custom formatted header cell for field 'name' -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>

      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(name)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder text-h6">
            {{ data.item.name }}
          </span>
        </div>
      </template>

      <template v-slot:cell(status)="data">
        <span
          class="label label-lg font-weight-bold label-light-info label-inline"
          >{{ data.item.status }}</span
        >
      </template>

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>

      <template #empty>
        <AppEmptyList
          text-top="No away applications"
          text-bottom="Go to entitlement to apply away status."
        ></AppEmptyList>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        size="lg"
        pills
        aria-controls="away-entitlement-page-dt"
        @change="handlePageChange"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";

export default {
  name: "PersonalAwayApplicationTable",
  components: { AppEmptyList, AppLoader },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      isBusy: false,
      fields: [
        {
          key: "name",
          label: "Away Name",
          stickyColumn: true,
          variant: "light",
        },
        { key: "from_date", label: this.$t("EDUCATION.FROM") },
        { key: "to_date", label: this.$t("EDUCATION.TO") },
        { key: "unit.requested", label: this.$t("HOLIDAY.DAY") },
        { key: "reason", label: this.$t("ENTITLEMENT.REASON") },
        { key: "status", label: this.$t("JOB.STATUS") },
      ],
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    cancelLeaveApplication() {},

    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchPersonalAwayApplicationList")
        .finally(() => (this.isBusy = false));
    },

    /**
     * Handle page change event.
     *
     * @param page
     */
    handlePageChange(page) {
      console.log("Current page : ", page);

      this.$store.commit("setPersonalAwayApplicationListPage", page);
      this.fetch();
    },
  },

  computed: {
    ...mapGetters({
      items: "getPersonalAwayApplicationList",
      pagination: "getPersonalAwayApplicationListPagination",
    }),
  },
};
</script>

<style scoped></style>
